import * as React from 'react'

/** Passed a ref and an array of dependencies,
 *  triggers a focus event on the referent if at least one of
 *  the dependencies change. Useful for managing focus for keyboard and screen reader users.
 *  @example
 *  // an element referenced by 'ref'
 *  // will focus when 'page' or 'content' updates.
 *  const focusRef = useConditionalFocus(ref, [page, content]);
 */

export const useConditionalFocus = (
  ref: React.RefObject<HTMLElement>,
  deps: unknown[]
): void => {
  React.useEffect(() => {
    if (ref && ref.current) {
      ref.current.focus()
    }
  }, deps)
}

export default useConditionalFocus
