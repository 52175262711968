import React from 'react'
import styled from 'styled-components'
import ViewGridComponent from '@components/ViewGridComponent'
import useIsMobile from '@hooks/useIsMobile'

// Hooks
import useSetBackgroundColor from '../../hooks/useSetBackgroundColor'
import { useSectionWidth } from '../../hooks/useSectionWidth'

type SectionComponentProps = {
  removePadding?: boolean
  forwardedAs?: 'footer'
  id?: string
  removeMinHeight?: boolean
} & React.HTMLProps<HTMLDivElement>

const SectionComponent = React.forwardRef<
  HTMLDivElement,
  SectionComponentProps
>(({ children, removePadding, removeMinHeight, id, forwardedAs }, ref) => {
  /*
  Ref is forwarded mainly to trigger animations contained within the section 
  when a % of the container is in view (this gives better control than triggering 
  when a % of the animation itself is in view, b/c the the container is larger). 
  It is passed to the inner content wrapper. The ref to trigger background changes is passed to the root.  
  */
  const { ref: backgroundRef } = useSetBackgroundColor()
  const { maxWidth, width } = useSectionWidth()
  const isMobile = useIsMobile()

  return (
    <Section
      ref={backgroundRef}
      as={forwardedAs}
      removePadding={removePadding}
      removeMinHeight={isMobile || removeMinHeight}
      id={id}
    >
      <ContentWrapper width={width} maxWidth={maxWidth} ref={ref}>
        <ViewGridComponent>{children}</ViewGridComponent>
      </ContentWrapper>
    </Section>
  )
})

type SectionProps = {
  removePadding?: boolean
  removeMinHeight?: boolean
}

const Section = styled.section<SectionProps>`
  display: flex;
  position: relative;
  padding: ${({ removePadding }) => (removePadding ? '0' : '25vh 0')};
  justify-content: center;
  align-items: center;
  /* overflow-x: hidden; */
  box-sizing: border-box;
  min-height: ${({ removeMinHeight }) => (removeMinHeight ? '0' : '100vh')};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ removePadding }) => (removePadding ? '0' : '20vh 0 0 0')};
  }
`
export const ContentWrapper = styled.div<{ width: string; maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ width }) => width};
`

export default SectionComponent
