import * as React from 'react'
import styled from 'styled-components/macro'
import useIsMobile from '@hooks/useIsMobile'
import SocialIcon, { SocialIconId } from '@components/SocialIconComponent'

const IconList = styled.ul<{ isMobile?: boolean; removeMargin: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme, removeMargin }) =>
    removeMargin ? '0' : theme.spacing.base};
  padding-bottom: ${({ isMobile, theme }) =>
    isMobile ? theme.spacing.xl : '0'};
`

type Icons = {
  iconType: SocialIconId
  ariaLabel: string
  href: string
}[]

const SocialIconList: React.FC<{
  className?: string
  removeMargin?: boolean
  icons: Icons
}> = props => {
  const isMobile = useIsMobile()

  return (
    <IconList
      isMobile={isMobile}
      className={props.className}
      removeMargin={Boolean(props.removeMargin)}
    >
      {props.icons.map(item => (
        <SocialIcon
          href={item.href}
          ariaLabel={item.ariaLabel}
          icon={item.iconType}
        />
      ))}
    </IconList>
  )
}
export default SocialIconList
