exports.shouldUpdateScroll = ({
  routerProps: {
    location: { pathname },
  },
}) => {
  /* Any route you want to scroll to top */
  if ([`/`, `/our-work`, `/about-us`, `/blog`, `/quote`].includes(pathname)) {
    document.body.scrollTo(0, 0)
  }
}

// https://www.gatsbyjs.com/plugins/gatsby-plugin-gdpr-cookies/#tracking-routes
// The gatsby-plugin-gdpr-cookies says that it fires an event on route change, when it doesn't, hence the below snippet, if we are getting double fires then we need to look here!
// dataLayer is an array of events setup by GTM to track events just FYI
exports.onRouteUpdate = ({ location, prevLocation }) => {
  // As onRouteUpdate is called when a user clicks on an anchor link on the pillar page, we need to stop it from pushing an event into GTM saying the page has changed page as they have not
  if (prevLocation && location) {
    if (
      prevLocation.pathname.replaceAll('/', '') ===
      location.pathname.replaceAll('/', '')
    )
      return
  }

  if (window && window.dataLayer)
    window.dataLayer.push({ event: 'gatsbyRouteChange' })
}
