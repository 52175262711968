import { FadeIn } from '@components/Animations'
import { Aws, BCorp, ChwaraeTeg, LivingWage } from '@components/IconComponent'
import fourDayWeek from '../../assets/4dayweek.png'
import escape100 from '../../assets/escape100.png'
import useIsMobile from '@hooks/useIsMobile'
import Grid from '@react-css/grid'
import React from 'react'
import styled from 'styled-components/macro'

const A = styled.a`
  display: grid;
  place-items: center;
  & > img {
    width: 100%;
  }
`

const FourDayWeek = () => <img src={fourDayWeek} alt='Four day week' />
const Escape100 = () => (
  <A href='https://www.escapethecity.org/organisation/big-lemon'>
    <img
      style={{ filter: 'grayscale(100%)' }}
      src={escape100}
      alt='Escape the city top 100 winner'
    />
  </A>
)

export const CertificationList = styled.ul<{ isMobile: boolean }>`
  display: flex;
  place-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme, isMobile }) =>
    isMobile ? theme.spacing.lg : theme.spacing.xl};
`

const icons = [
  { label: 'Amazon Web Services', Icon: Aws },
  { label: 'Escape The City winners', Icon: Escape100 },
  { label: 'BCorp', Icon: BCorp },
  { label: 'Chwarae Teg', Icon: ChwaraeTeg },
  { label: 'Living wage employers', Icon: LivingWage },
  { label: 'Four day work week', Icon: FourDayWeek },
]

const Certifications = () => {
  const isMobile = useIsMobile()

  return (
    <Grid.Item column={isMobile ? '1 / 13' : '2 / 12'}>
      <CertificationList isMobile={isMobile}>
        {icons.map(({ Icon, label }, i) => (
          <li key={`certification-icon-${i}`}>
            <FadeIn>
              <IconContainer>
                <Icon
                  alt={label}
                  fill={'var(--color-certifications-logo)'}
                  height='100%'
                  width='100%'
                />
              </IconContainer>
            </FadeIn>
          </li>
        ))}
      </CertificationList>
    </Grid.Item>
  )
}

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  height: ${({ theme }) => theme.spacing.xl};
  width: ${({ theme }) => theme.spacing.xl};
  position: relative;
`

export default Certifications
