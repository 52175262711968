import { Theme } from './styled'

// Constants
import {
  blue,
  coral,
  darkPurple,
  paleBlue,
  yellow,
  gray,
  lightGray,
  white,
  purple,
  green,
  bodyText,
} from './colors'
const buttonShadowLight = '#FAD6D1'
const buttonShadowDark = '#2E0B05'
const shadowDefault = 'rgba(0,0,0,0.15)'

export const spacer = {
  light: '#FFFFFF15',
  dark: '#00000015',
}

const buttonOnLight = {
  text: white.default,
  shadow: buttonShadowDark,
}
const buttonOnDark = {
  text: white.default,
  shadow: buttonShadowLight,
}

/* -------------------------------------------------------------------------- */
/*                                 Base Theme                                 */
/* -------------------------------------------------------------------------- */
const navigation: Theme['colors']['navigation'] = {
  background: 'rgba(21,20,51,0.0)',
  text: {
    default: bodyText.darkPurple,
    light: bodyText.darkPurple,
    dark: bodyText.darkPurple,
  },
  logo: {
    light: '#ffffff',
    dark: '#242256',
    default: '#ffffff',
  },
  button: {
    text: white.default,
    shadow: buttonShadowDark,
  },
}

const mobileNav: Theme['colors']['mobileNavigation'] = {
  background: blue.default,
  text: white,
  button: buttonOnLight,
  lemonOutline: purple,
  lemonLines: white,
}

export const theme: Theme = {
  padding: { lg: '84px' },
  isDark: false,

  shadow: {
    sm: `0 4px 12px -2px ${shadowDefault}`,
    md: `0 8px 24px -4px ${shadowDefault}`,
    lg: `0 16px 32px -8px ${shadowDefault}`,
    xl: `0 24px 48px -12px ${shadowDefault}`,
  },
  borderRadius: '10px',
  colors: {
    gray: gray,
    lightGray: lightGray,
    darkPurple: darkPurple,
    purple: purple,
    paleBlue: paleBlue,
    blue: blue,
    yellow: yellow,
    coral: coral,
    green: green,
    headings: gray,
    success: '#D6D6FF',
    error: '#D6D6FF',
    button: {
      text: white.default,
      shadow: buttonShadowDark,
    },
    highlight: {
      background: yellow.default,
      text: gray.default,
    },
    accent: blue,
    background: white,
    navigation: navigation,
    mobileNavigation: mobileNav,
    link: blue,
    text: bodyText.white,

    certificationsLogo: {
      dark: '#ffffff',
      light: '#212121',
    },
  },
  breakpoints: {
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1140px',
    xxl: '1380px',
  },
  fonts: {
    primary: 'nocturne-serif, serif',
    display: 'neue-haas-grotesk-display, serif',
    secondary: 'neue-haas-unica',
  },
  spacing: {
    xs: '1rem',
    sm: '2rem',
    base: '2.75rem',
    lg: '3.5rem',
    xl: '5rem',
    xl2: '10rem',
    xl3: '15rem',
    mobile: {
      xs: '0.8rem',
      sm: '1.6rem',
      base: '2.3rem',
      lg: '3rem',
      xl: '4.25rem',
      xl2: '8.5rem',
      xl3: '12.8rem',
    },
  },

  offsets: {
    imageDesktop: {
      right: 'transform: translate(min(4vw, 6.25rem))',
      left: 'transform: translate(max(-4vw, -6.25rem))',
    },
  },
  heights: {
    header: '8rem',
    mobileHeader: '6rem',
  },
} as const
/* -------------------------------------------------------------------------- */
/*                                 White Theme                                */
/* -------------------------------------------------------------------------- */
export const whiteTheme: Theme = {
  ...theme,
  isDark: false,

  colors: {
    ...theme.colors,
    background: white,
    spacer: spacer.dark,
    accent: blue,
    headings: gray,
    text: bodyText.white,
    button: buttonOnLight,
    highlight: {
      background: yellow.default,
      text: gray.default,
    },
    navigation: {
      ...navigation,
      background: 'rgba(255,255,255, 0.70)',
      text: gray,
      button: buttonOnLight,
    },
    mobileNavigation: {
      ...theme.colors.mobileNavigation,
      lemonOutline: darkPurple,
      lemonLines: darkPurple,
    },
  },
}
/* -------------------------------------------------------------------------- */
/*                               Pale Blue Theme                              */
/* -------------------------------------------------------------------------- */
export const paleBlueTheme: Theme = {
  ...theme,
  isDark: false,

  colors: {
    ...theme.colors,
    spacer: spacer.dark,
    background: paleBlue,
    accent: blue,
    headings: blue,
    text: bodyText.paleBlue,
    button: buttonOnLight,
    highlight: {
      background: yellow.default,
      text: blue.default,
    },
    navigation: {
      ...navigation,
      background: 'rgba(246,246,255, 0.70)',
      text: gray,
      button: buttonOnLight,
    },
    mobileNavigation: {
      ...theme.colors.mobileNavigation,
      lemonOutline: gray,
      lemonLines: gray,
    },
  },
}
/* -------------------------------------------------------------------------- */
/*                                Purple Theme                                */
/* -------------------------------------------------------------------------- */

export const purpleTheme: Theme = {
  ...theme,
  isDark: false,

  colors: {
    ...theme.colors,
    text: bodyText.purple,
    spacer: spacer.dark,
    headings: darkPurple,
    background: purple,
    accent: darkPurple,
    button: buttonOnLight,
    highlight: {
      background: 'transparent',
      text: darkPurple.default,
    },
    navigation: {
      ...navigation,
      background: 'rgba(165,165,255, 0.70)',
      text: purple,
      button: buttonOnLight,
    },
    mobileNavigation: {
      ...theme.colors.mobileNavigation,
      lemonOutline: darkPurple,
      lemonLines: darkPurple,
    },
  },
}
/* -------------------------------------------------------------------------- */
/*                                 Blue Theme                                 */
/* -------------------------------------------------------------------------- */
export const blueTheme: Theme = {
  ...theme,
  isDark: true,

  colors: {
    ...theme.colors,
    spacer: spacer.light,
    text: bodyText.blue,
    headings: white,
    background: blue,
    accent: yellow,
    button: buttonOnDark,
    highlight: {
      background: 'transparent',
      text: yellow.default,
    },
    navigation: {
      ...navigation,

      background: 'rgba(60,56,143, 0.70)',
      text: white,
      button: buttonOnDark,
    },
    mobileNavigation: {
      ...theme.colors.mobileNavigation,
      lemonOutline: purple,
      lemonLines: white,
    },
  },
}

/* -------------------------------------------------------------------------- */
/*                              Dark Purple Theme                             */
/* -------------------------------------------------------------------------- */
export const darkPurpleTheme: Theme = {
  ...theme,
  isDark: true,

  colors: {
    ...theme.colors,
    spacer: spacer.light,
    text: bodyText.darkPurple,
    headings: white,
    background: darkPurple,
    accent: yellow,
    highlight: {
      background: 'transparent',
      text: purple.default,
    },
    button: buttonOnDark,
    navigation: {
      ...navigation,

      background: 'rgba(36,34,86, 0.70)',
      text: white,
      button: buttonOnDark,
    },
    mobileNavigation: {
      ...theme.colors.mobileNavigation,
      lemonOutline: purple,
      lemonLines: white,
    },
  },
}

/* -------------------------------------------------------------------------- */
/*                                 Hero Theme                                 */
/* -------------------------------------------------------------------------- */

export const heroTheme: Theme = {
  ...darkPurpleTheme,
  colors: {
    ...darkPurpleTheme.colors,
    background: purple,
    navigation: {
      ...navigation,
      ...darkPurpleTheme.colors.navigation,
      background: 'rgba(21,20,51,0.0)',
    },
  },
}

export const heroThemeAlt: Theme = {
  ...blueTheme,
  colors: {
    ...blueTheme.colors,
    navigation: {
      ...navigation,
      ...darkPurpleTheme.colors.navigation,
      background: 'rgba(21,20,51,0.0)',
    },
  },
}
