import * as React from 'react'
import { IconProps } from './types'

export const ChwaraeTeg = (props: IconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 200 103.2'
    fill='var(--color-certification)'
    {...props}
  >
    <path d='M154.3 87.3c-1.2-.2-2.7-.2-4.2-.6-5.6-1.4-9.2-7-8.5-12.8.1-1.1 1.2-1.5 2-.9.2.2.4.4.6.7 3 3.6 6.4 4.5 10.8 2.9 4.6-1.7 8.2-4.8 11.6-8.1 5.1-5 9.4-10.6 13.5-16.4 1.3-1.8 1.9-1.8 3.4-.2 4.8 5.1 9.6 10.3 14.5 15.4.8.8 1 1.6-.1 2.3-11.6 7.2-23.5 13.8-37 17-.7.2-1.4.3-2.1.4-1.4 0-2.8.2-4.5.3zM74.5 87.2c-4.1 3.5-8.6 3.6-12.9 1.1-4.8-2.7-6.6-7.3-6.2-12.6.5-6.3 3.8-10.3 9.2-11.5 3.6-.9 6.9-.4 9.9 2.3.1-.7.2-1.2.3-1.9h7.3c0 .4.1 16 .1 23.3 0 3.5-.3 6.9-2.8 9.7-3.6 4-8.2 4.6-13.2 3.9-5.8-.8-9.5-4.6-10.3-10.4h7.9c1.8 3.7 4.1 4.8 7.3 3.8 2.9-.8 4.1-3.6 3.4-7.7zm.9-10.3c0-3.6-2.6-6.3-6-6.3-3.6 0-6.3 2.6-6.2 6.2 0 3.6 2.7 6.4 6.2 6.4 3.4-.1 6-2.7 6-6.3zM116.8 16.5c.1-.6.1-1.2.1-1.8h7.4v24.5H117c-.1-.6-.1-1.1-.2-2-2.9 2.8-6.2 3.3-9.8 2.5-2.9-.6-5.2-2.1-6.9-4.5-3.8-5.1-3.4-13.1.8-17.7 3.1-3.4 10.5-5.5 15.9-1zm.7 10.4c0-3.6-2.6-6.2-6.1-6.2-3.5 0-6.2 2.7-6.2 6.2 0 3.6 2.7 6.4 6.2 6.4 3.4 0 6.1-2.7 6.1-6.4zM171.3 14.8v24.4h-7.4c0-.6-.1-1.1-.2-1.9-3.1 2.9-6.6 3.3-10.4 2.3-2.7-.7-4.9-2.3-6.5-4.6-3.5-5-3.1-12.9.9-17.2 4.2-4.5 10-5 16-1.3 0-.6.1-1.1.1-1.7h7.5zm-6.9 12.1c0-3.6-2.6-6.2-6.1-6.2-3.5 0-6.2 2.8-6.2 6.3.1 3.6 2.7 6.2 6.2 6.2 3.6.1 6.2-2.6 6.1-6.3zM15.1 58.7H7v-6.8h24.2v6.8h-8c0 .7-.1 1.2-.1 1.8V87c0 2.5.3 2.2-2.3 2.2h-5.7V58.7z' />
    <path d='M140.1 58.6c1.2.2 2.7.2 4.2.6 5.6 1.4 9.2 7 8.5 12.8-.1 1.1-1.2 1.5-2 .9-.2-.2-.4-.4-.6-.7-3-3.6-6.4-4.5-10.8-2.9-4.6 1.7-8.2 4.8-11.6 8.1-5.1 5-9.4 10.6-13.5 16.4-1.3 1.8-1.9 1.8-3.4.2-4.8-5.1-9.6-10.3-14.5-15.4-.8-.8-1-1.6.1-2.3 11.6-7.2 23.5-13.8 37-17 .7-.2 1.4-.3 2.1-.4 1.4-.1 2.8-.2 4.5-.3zM191.5 31.3c-1.5 2.1-4.1 3.1-6.6 2.3-.1 0-.2-.1-.4-.1-2-.8-3.2-2.6-3.2-5h17.5c.8-7.5-3.2-13.4-9.8-14.5-7.5-1.3-13.9 2.7-15.4 9.6-1.6 7.5 2.2 14.2 8.9 15.9 7.3 1.9 14.3-1.4 16.3-7.7l-6.9-1.2-.4.7zm-4.8-11.8c2.5.1 4.4 1.6 4.8 3.8h-9.8c.6-2.4 2.5-3.9 5-3.8zM45.7 81.2c-1.5 2.1-4.1 3.1-6.6 2.3-.1 0-.2-.1-.4-.1-2-.8-3.2-2.6-3.2-5H53c.8-7.5-3.2-13.4-9.8-14.5-7.5-1.3-13.9 2.7-15.4 9.6-1.6 7.5 2.2 14.2 8.9 15.9C44 91.3 51 88 53 81.7l-6.9-1.2-.4.7zm-4.8-11.8c2.5.1 4.4 1.6 4.8 3.8h-9.8c.6-2.4 2.5-3.9 5-3.8zM12.6 29.2c-4.4-4.6-4.3-12.5.1-17.4 4.4-4.8 10.9-4.5 17.5 1V3.2l-1.5-.6c-.1 0-.1 0-.2-.1-.1 0-.2-.1-.3-.1l-.6-.2C21-.1 14.5.5 8.5 4.8c-6.3 4.5-9 13.4-6.7 21.3 3.1 11 14.3 16.7 26 12.8 1.2-.4 2.4-1.3 2.4-1.3v-2.2-7.1c-6.3 5.6-12.9 5.8-17.6.9zM56.9 22.1c0-11.4-15.5-8.9-16.2-5.5V1h-7.5V2.6c.1 11.3 0 22.6 0 34v2.7h7.6v-9.9c0-1.6 0-3.3.2-4.9.4-2.7 2.4-4.2 4.8-4 2.3.2 3.6 1.7 3.6 4.5.1 3.6 0 7.2 0 10.8v3.5H57v-.5c0-5.6-.1-11.1-.1-16.7zM92.5 14.6c-.1 0-.1 0 0 0h-1.4l-2.2 5.5c-1 2.4-2 4.9-3.1 7.5-.3-.7-.6-1.2-.8-1.7-1-2.3-1.9-4.5-2.9-6.8l-.9-2.1c-.2-.4-.3-.8-.5-1.3 0-.1-.1-.2-.1-.2l-.4-.9h-3.4l-.3.8c0 .1-.1.2-.1.3-.1.4-.3.8-.5 1.1l-1.4 3.5c-.8 2-1.7 3.9-2.5 5.9-.2.4-.4.7-.7 1.3-.3-.6-.5-1-.6-1.4-.6-1.5-1.3-3.1-1.9-4.6L66 14.7h-7.6c0 .2 0 .4.1.5l9 19.5 2.2 4.8H73l.5-1.1c.1-.3.3-.7.5-1.2.1-.2.2-.4.2-.6.1-.2.2-.5.3-.7 1.3-3.1 2.6-6.2 3.9-9.6.3.7.5 1.1.7 1.6 1.1 2.8 2.3 5.5 3.4 8.3l1.3 3.4h3.6l.5-1.1c0-.1.1-.1.1-.2 3.3-7.5 6.8-14.9 10.3-22.4.1-.3.2-.7.4-1.1l-6.2-.2zM134.7 16.9c-.1-.9-.1-1.5-.2-2.2h-7.4V39.3h7.5v-2.1c.1-4.1 0-8.2.3-12.2.2-2.7 2.2-4.3 4.9-4.2 1.2 0 2.4.5 3.7.7.3-2.2.6-4.5 1-6.9-3.8-1.3-7-.7-9.8 2.3z' />
  </svg>
)

export default ChwaraeTeg
