import * as React from 'react'

// Components
import AddressComponent from '@components/AddressComponent'
import FooterBottom from '@components/FooterBottomComponent'
import FooterColumn from '@components/FooterColumnComponent'
import FormContactComponent from '@components/FormContactComponent'
import ResourceList from '@components/ResourceListComponent/ResourceListComponent'
import SectionComponent from '@components/SectionComponent'
import SeparatorComponent from '@components/SeparatorComponent'
import SocialIconList from '@components/SocialIconListComponent'

// Hooks
import Certifications from '@components/Certifications'
import SpacerComponent from '@components/SpacerComponent'
import { Paragraph } from '@components/Typography'
import useIsMobile from '@hooks/useIsMobile'
import { useTheme } from 'styled-components/macro'

const FooterLayout: React.FC = () => {
  const theme = useTheme()
  const isMobile = useIsMobile()

  const hasSeparator = !isMobile

  return (
    <SectionComponent removePadding forwardedAs='footer'>
      <SpacerComponent bottom='xl3' />
      <Certifications />
      <SpacerComponent bottom='xl3' />
      <FooterColumn
        heading='Contact Us'
        css={`
          grid-column: ${isMobile ? '1 / 13' : '1 / 4'};
          align-self: stretch;
          ${isMobile &&
          `display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;`};
        `}
      >
        <AddressComponent />
        <SocialIconList
          icons={[
            {
              href: 'https://twitter.com/biglemontweets',
              ariaLabel: 'Go to our Twitter',
              iconType: 'twitter',
            },
            {
              href: 'https://www.linkedin.com/company/big-lemon-creative',
              ariaLabel: 'Go to our LinkedIn',
              iconType: 'linkedin',
            },
            {
              href: 'https://www.facebook.com/biglemoncreative',
              ariaLabel: 'Go to our Facebook',
              iconType: 'facebook',
            },
            {
              href: 'https://www.instagram.com/biglemonsnaps',
              ariaLabel: 'Go to our Instagram',
              iconType: 'instagram',
            },
          ]}
        />
      </FooterColumn>

      <FooterColumn
        heading='Resources'
        css={`
          grid-column: ${isMobile ? '1 / 13' : '5 / 8'};
          ${isMobile &&
          `display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;`};
        `}
      >
        <ResourceList />
      </FooterColumn>

      <FooterColumn
        heading='Get in touch'
        css={`
          grid-column: ${isMobile ? '1 / 13' : '9 / 13'};
          align-self: stretch;
          ${isMobile &&
          `display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                text-align: left;`};
        `}
      >
        <Paragraph size='xs'>
          Drop us a line or stay in touch to get the juicy stuff to your inbox
        </Paragraph>
        <br />
        <FormContactComponent submitId='footerContactForm' />
      </FooterColumn>
      {hasSeparator && (
        <SeparatorComponent
          css={`
            grid-column: 1 / 13;
            align-self: stretch;
            margin: ${theme.spacing.lg} 0;
            ${isMobile && `text-align: center;`}
          `}
        />
      )}
      <FooterBottom
        css={`
          align-self: stretch;
          grid-column: ${isMobile ? '1 / 13' : '1 / 13'};
          ${isMobile &&
          `display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  width: 100%;
                  text-align: left;`};
        `}
      />
    </SectionComponent>
  )
}

export default FooterLayout
