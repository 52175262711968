export const yellow = {
  light: '#FFFE70',
  default: '#FFFE3F',
  dark: '#FFFE0A',
}

export const darkPurple = {
  light: '#34317C',
  default: '#242256',
  dark: '#151433',
}

export const blue = {
  light: '#4B46B4',
  default: '#3C388F',
  dark: '#2D2A6A',
}

export const purple = {
  light: '#D6D6FF',
  default: '#A5A5FF',
  dark: '#7070FF',
}

export const paleBlue = {
  light: '#FFFFFF',
  default: '#F6F6FF',
  dark: '#C2C2FF',
}

export const coral = {
  light: '#EE7663',
  default: '#E94F37',
  dark: '#D33117',
}

export const gray = {
  light: '#3B3B3B',
  default: '#212121',
  dark: '#080808',
}

export const white = {
  light: '#ffffff',
  default: '#ffffff',
  dark: '#E6E6E6',
}

export const lightGray = {
  light: '#c8c8c8',
  dark: '#e8e8e8',
  default: '#9190aa',
}

export const green = {
  light: '#00c7b7',
  dark: '#00c7b7',
  default: '#00c7b7',
}

export const bodyText = {
  white: '#4d4d4d',
  paleBlue: '#212121',
  purple: '#3e3c78',
  blue: '#D8D7E8',
  darkPurple: '#D3D2DD',
}
