import * as React from 'react'

import { useIsMobile } from '.'

export const useSectionWidth = () => {
  const isMobile = useIsMobile()

  const maxWidth = '1200px'
  const width = isMobile ? '80vw' : '90vw'

  return {
    maxWidth,
    width,
  }
}
