import * as React from 'react'
import Grid, { GridContainerProps } from '@react-css/grid'
import styled, { StyledProps, useTheme } from 'styled-components/macro'

// Hooks
import useIsMobile from '@hooks/useIsMobile'
import { useSectionWidth } from '@hooks/useSectionWidth'

type ViewGrid = React.FC<StyledProps<any> & GridContainerProps> & {
  Item: typeof StyledGridItem
  Row: typeof Row
  FullScreen: typeof FullScreen
  SubGrid: typeof SubGrid
}

type ViewGridConfig = {
  columnCount: number
  columnGap: number // in REM
}

type ViewGridProps = {
  columnCount: number
  columnGap: string
  columns: string
  columnsHalfGrid: string
  halfSpanStart: string
  halfSpanEnd: string
  ref?: Element
}

const useViewGrid = (): ViewGridProps => {
  const theme = useTheme()
  const isMobile = useIsMobile()

  const initialLoad = () => {
    const config: ViewGridConfig = {
      columnCount: 12,
      columnGap: isMobile ? 0.3125 : 2, // in rem
    }

    const gapCount = config.columnCount - 1
    const gapTotal = `${config.columnGap * gapCount}rem`
    const columnsWidthTotal = `calc(100% - ${gapTotal})` // all columns
    const columnWidth = `calc(${columnsWidthTotal} / ${config.columnCount})` // individual column
    // makes column count many cols of columnWidth size
    // we use minmax because the min size of a column is auto
    // see https://css-tricks.com/preventing-a-grid-blowout/
    const columns = `repeat(${config.columnCount}, minmax(0, ${columnWidth}))`

    // Half grid config
    // TODO: warn if odd columns
    const halfGapCount = config.columnCount / 2 - 1
    const halfGapWidthTotal = `${config.columnGap * halfGapCount}rem`
    const columnHalfGridWidthTotal = `calc(100% - ${halfGapWidthTotal})`
    const columnHalfGridWidth = `calc(${columnHalfGridWidthTotal} / ${
      config.columnCount / 2
    })`
    const columnsHalfGrid = `repeat(${
      config.columnCount / 2
    }, minmax(0, ${columnHalfGridWidth}))`

    const halfSpanStart = `1 / ${config.columnCount / 2 + 1}` // +1 b/c we count lines, not columns: ;
    const halfSpanEnd = `${config.columnCount / 2 + 1} / ${
      config.columnCount + 1
    }`
    return {
      columnCount: config.columnCount,
      columnGap: `${config.columnGap}rem`,
      columns,
      columnsHalfGrid,
      halfSpanStart,
      halfSpanEnd,
    }
  }

  const {
    columnCount,
    columnGap,
    columns,
    columnsHalfGrid,
    halfSpanStart,
    halfSpanEnd,
  } = initialLoad()

  return {
    columnCount,
    columnGap,
    columns,
    columnsHalfGrid,
    halfSpanStart,
    halfSpanEnd,
  }
}

/** Wrap this grid component around views in the app.
 * Follow this api:
 * https://www.npmjs.com/package/@react-css/grid
 * You can pass any props you would to Grid
 * to ViewGrid, they're spread on the Grid.
 * Customize to your heart's content.
 *
 * To pass semantic elements, use 'forwardedAs'
 *
 * Also, see these extensions:
 * ViewGrid.Row, ViewGrid.FullScreen
 *
 * You can use <GridItem.Row> to create a full
 * width row layout across the grid.
 *
 * You can use <GridItem.FullScreen> to create
 * a full screen view inside the grid
 * (note: no grid inside <GridItem.FullScreen> will be defined)
 *
 * @example
 * import ViewGrid from 'path/to/ViewGridComponent'
 *
 * // using Grid.Item
 *
 * const SomeView = () => {
 *  <ViewGrid forwardedAs="section">
 *    <ViewGrid.Item
 *      forwardedAs="article"
 *      columnStart={2}  // or: column="2 / 5"
 *      columnEnd={5}
 *      alignSelfStretch
 *     >
 *        <SomeComponent/>
 *    </ViewGrid.Item>
 *  </ViewGrid>
 * }
 *
 */

const ViewGridComponent: ViewGrid = props => {
  const gridProps = useViewGrid()

  return (
    <StyledGrid
      autoRows='minmax(max-content, auto)'
      columnGap={gridProps.columnGap}
      columns={gridProps.columns}
      as={props.forwardedAs}
      {...props}
    >
      {props.children}
    </StyledGrid>
  )
}

type SubGridSpan = 'full' | 'half'

type SubGridProps = {
  span?: SubGridSpan
  order?: number
  alignSelf?: 'start' | 'center'
} & GridContainerProps

// TODO: pass through props

/** Defines a subgrid of the main ViewGrid.
 * Accepts a prop 'span' which determines how much
 * of the parent grid it occupies. Values include 'half' and 'full'.
 * Also accepts an 'order' prop to allow it to be re-ordered
 * alongside other grid items.
 *
 * @example
 *
 * // Half width of parent grid
 *
 * <Grid.SubGrid order={1} span="half">
 *  <Grid.Item>
 *    ...
 *  </Grid.Item>
 * </Grid.SubGrid>
 */

const SubGrid: React.FC<SubGridProps> = ({
  span = 'full',
  order,
  ref,
  ...props
}) => {
  const gridProps = useViewGrid()

  const columns = (() => {
    if (span === 'full') return gridProps.columns
    if (span === 'half') return gridProps.columnsHalfGrid
    // default full width
    return gridProps.columns
  })()

  const _span = (() => {
    if (span === 'full') return gridProps.columnCount
    if (span === 'half') return gridProps.columnCount / 2
    // default full width
    return gridProps.columns
  })()

  const alignSelf = props.alignSelf ?? 'start'

  return (
    <StyledGridItem
      alignSelfStart={alignSelf === 'start'}
      alignSelfCenter={alignSelf === 'center'}
      column={`span ${_span}`}
      order={order}
    >
      <StyledSubGrid
        alignItems='start'
        autoRows='minmax(max-content, auto)'
        columnGap={gridProps.columnGap}
        columns={columns}
        {...props}
      >
        {props.children}
      </StyledSubGrid>
    </StyledGridItem>
  )
}

const StyledSubGrid = styled(Grid)`
  width: 100%;
  margin: 0 auto;
  background-color: transparent;
`

const StyledGrid = styled(Grid)<{ forwardedAs?: string }>`
  margin: 0 auto;
  background-color: transparent;
`

/** Convenience method. Defined a full width subgrid.
 *
 * @example
 *
 * <Grid.Row order={1} >
 *    <Grid.Item>
 *      ...
 *    </Grid.Item>
 * </Grid.Row>
 *
 * // equivalent to
 *
 * <Grid.SubGrid order={1} span="full">
 *    <Grid.Item>
 *    ...
 *    </Grid.Item>
 * </Grid.SubGrid>
 *
 */

const Row: React.FC<SubGridProps> = props => <SubGrid span='full' {...props} />

/* Creates a full screen item. Using the grid internally is no longer possible,
so positioning must be defined by the full screen container */

const FullScreen: React.FC = props => {
  const { maxWidth, width } = useSectionWidth()

  const sectionWidth = `min(${maxWidth}, ${width})`

  const gutter = `calc(calc(100vw - ${sectionWidth}) / 2)`

  return <FullScreenDiv gutter={gutter}>{props.children}</FullScreenDiv>
}

const FullScreenDiv = styled.div<{ gutter: string }>`
  width: 100vw;
  position: relative;
  right: ${({ gutter }) => `${gutter}`};
`

/** Just like Grid.Item,
 * but as a styled component.
 * Accepts an optional prop 'order'
 * which specifies the grid item order.
 *
 * @example
 *
 * <ViewGrid>
 *    <ViewGrid.Item order={1} alignSelfStart>
 *      ...underpants ...profit
 *    </ViewGrid.Item>
 * </ViewGrid>
 *
 */

const StyledGridItem = styled(Grid.Item)<{
  forwardedAs?: string
  order?: number
}>`
  ${({ order }) => {
    if (order) return `order: ${order}`
  }};
`

ViewGridComponent.Item = StyledGridItem
ViewGridComponent.Row = Row
ViewGridComponent.FullScreen = FullScreen
ViewGridComponent.SubGrid = SubGrid

export default ViewGridComponent
