import React from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { Context as ResponsiveContext } from 'react-responsive'

import {
  purpleTheme,
  whiteTheme,
  darkPurpleTheme,
  paleBlueTheme,
} from '../themes'

// Layouts
import HeaderLayout from './HeaderLayout'
import FooterLayout from './FooterLayout'
import { GlobalStyle } from './GlobalStyle'
import { CookieBanner } from './CookieBanner'
import { createPropertyMap } from '@src/utils/createThemePropertyMap'
import { useIsTabbing, useScrollOnFocus } from '../hooks'

type MainLayoutProps = {
  pageContext: {
    layout: 'quote'
    theme: 'paleBlue' | 'white'
  }
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, pageContext }) => {
  const isTabbing = useIsTabbing()
  useScrollOnFocus(isTabbing)

  let theme = purpleTheme

  if (pageContext.theme === 'paleBlue') {
    theme = paleBlueTheme
  }

  if (pageContext.theme === 'white') {
    theme = whiteTheme
  }

  const themeMap = createPropertyMap(theme)

  if (pageContext.layout === 'quote') {
    return (
      <ResponsiveContext.Provider value={{ width: 500 }}>
        <ThemeProvider theme={theme}>
          <GlobalStyle {...themeMap} />
          <CookieBanner />
          <main>{children}</main>
        </ThemeProvider>
      </ResponsiveContext.Provider>
    )
  }

  return (
    <ResponsiveContext.Provider value={{ width: 500 }}>
      <ThemeProvider theme={darkPurpleTheme}>
        <CookieBanner />
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle {...themeMap} />
        <HeaderLayout />

        <main>{children}</main>
      </ThemeProvider>
      <ThemeProvider theme={darkPurpleTheme}>
        <FooterLayout />
      </ThemeProvider>
    </ResponsiveContext.Provider>
  )
}

export default MainLayout
