import useIsMobile from '@hooks/useIsMobile'
import { useAnimation } from 'framer-motion'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import AnimationContainer from './AnimationContainer'

type FadeInProps = {
  threshold?: number
  direction?: 'left' | 'right' | 'middle'
}

const FadeAndRotate: React.FC<FadeInProps> = ({
  children,
  threshold,
  direction,
}) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({ threshold: threshold, triggerOnce: true })
  const isMobile = useIsMobile()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    } else {
      controls.start('hidden')
    }
  }, [controls, inView, isMobile])

  let visible = {
    scale: [1, 1.1, 1.1],
    rotate: [1, -1, -1],
    opacity: [0, 1, 1],
    y: [20, -10, -10],
    x: [-32, -32, -32],
    transition: {
      duration: 1.5,
      delay: 0.25,
      ease: 'easeOut',
      times: [0, 0.5, 1],
    },
  }

  if (direction === 'right') {
    visible = {
      scale: [1, 1.1, 1.1],
      rotate: [-1, 1, 1],
      opacity: [0, 1, 1],
      y: [20, -10, -10],
      x: [32, 32, 32],
      transition: {
        duration: 1.5,
        delay: 0.25,
        ease: 'easeOut',
        times: [0, 0.5, 1],
      },
    }
  }

  if (direction === 'middle') {
    visible = {
      scale: [1, 1.1, 1.1],
      rotate: [-1, 1, 1],
      opacity: [0, 1, 1],
      y: [0, 0, 0],
      x: [0, 0, 0],
      transition: {
        duration: 1.5,
        delay: 0.25,
        ease: 'easeOut',
        times: [0, 0.5, 1],
      },
    }
  }

  if (isMobile) {
    visible = {
      scale: [1, 1.05, 1.05],
      rotate: [1, -1, -1],
      opacity: [0, 1, 1],
      y: [10, -5, -5],
      x: [0, 0, 0],
      transition: {
        duration: 1.5,
        delay: 0.25,
        ease: 'easeOut',
        times: [0, 0.5, 1],
      },
    }
  }

  return (
    <AnimationContainer
      ref={ref}
      animate={controls}
      initial='notOnScreen'
      variants={{
        visible: visible,
        hidden: {
          opacity: 0,
        },
      }}
    >
      {children}
    </AnimationContainer>
  )
}

export default FadeAndRotate
