import { Caption } from '@components/Typography'
import { Link as GatsbyLinker } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import * as React from 'react'
import styled from 'styled-components'

import { ArrowRight } from '../IconComponent'

type SquaredLinkComponentProps = {
  label: string
  href?: string
  to?: string
  useAnchor?: boolean
  className?: string
  target?: string
}

/**
 * @description  - Use this to link to internal and external page. Using the to props means its internal, using href means its external
 * @param href - This is an external link using a standard "<a>"" tag
 * @param to - This will be an internal link using the gatsby link
 */
const SquaredLinkComponent: React.FC<SquaredLinkComponentProps> = ({
  label,
  href,
  className,
  to,
  useAnchor,
  target,
}) => {
  // This is to handle anchor links in gatsby with it scrolling to the wrong location
  if (useAnchor && to) {
    return (
      <AnchorLinkStyled to={to}>
        <EdgyBox className={className}>
          <ButtonText>{label?.toUpperCase()}</ButtonText>
          <ArrowRight />
        </EdgyBox>
      </AnchorLinkStyled>
    )
  }

  // This is to handle internal linking
  if (to) {
    return (
      <GatsbyLink to={to}>
        <EdgyBox className={className}>
          <ButtonText>{label?.toUpperCase()}</ButtonText>
          <ArrowRight />
        </EdgyBox>
      </GatsbyLink>
    )
  }

  return (
    <Link href={href} target={target}>
      <EdgyBox className={className}>
        <ButtonText>{label?.toUpperCase()}</ButtonText>
        <ArrowRight />
      </EdgyBox>
    </Link>
  )
}

const ButtonText = styled(Caption)`
  color: var(--color-primary);
  text-size-adjust: 80%;
`

export const EdgyBox = styled.span`
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.1rem;
  background-color: ${({ theme: { colors } }) => colors.coral.default};
  width: max-content;
  color: ${({ theme: { colors } }) => colors.button.text};
  display: flex;
  gap: 0.625rem;
  align-items: center;

  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;

  box-shadow: ${({ theme }) => {
    return '-3px 3px 0px 0px ' + theme.colors.button.shadow
  }};
  cursor: pointer;
  &:hover {
    transform: translate(-1px, 1px);
    box-shadow: ${({ theme: { colors } }) =>
      '-2px 2px 0px 0px ' + colors.button.shadow};
  }

  &:active {
    transform: translate(-3px, 3px);
    box-shadow: ${({ theme: { colors } }) =>
      '-1.5px 1.5px 0px 0px ' + colors.button.shadow};
  }

  &:disabled {
    opacity: 0.5;
  }

  svg {
    fill: white;
    width: 0.75rem;
  }
`

const GatsbyLink = styled(GatsbyLinker)`
  color: inherit;
  text-decoration: none;
`

const AnchorLinkStyled = styled(AnchorLink)`
  color: inherit;
  text-decoration: none;
`

const Link = styled.a`
  color: inherit;
  text-decoration: none;
`

export default SquaredLinkComponent
