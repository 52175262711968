import * as React from 'react'
import { useInView } from 'react-intersection-observer'
import { DefaultTheme, useTheme } from 'styled-components'
import { debounce } from 'debounce'
import {
  createPropertyMap,
  CreatePropertyMapReturn,
} from '../utils/createThemePropertyMap'

const setProperty = (property: string, value: string | null) => {
  if (document) {
    const oldValue = document.documentElement.style.getPropertyValue(property)

    if (oldValue !== value) {
      document.documentElement.style.setProperty(property, value)
    }
  }
}

const updateColors = (theme: DefaultTheme) => {
  const props = createPropertyMap(theme)

  const keys = Object.keys(props)

  keys.forEach((key, index) => {
    // @ts-expect-error
    setProperty(key, props?.[key] as keyof CreatePropertyMapReturn)
  })
}

const useSetBackgroundColor = () => {
  const theme = useTheme()

  const { inView, ref } = useInView({ triggerOnce: false, threshold: 0.1 })

  React.useEffect(() => {
    if (inView) {
      debounce(updateColors, 50)(theme)
    }
  }, [inView])

  return {
    ref,
    inView,
  }
}

export default useSetBackgroundColor
