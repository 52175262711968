import styled from 'styled-components/macro'

type TitleProps = {
  shouldUseTheme?: boolean // Deprecated
  centered?: boolean
  font?: 'primary' | 'secondary' | 'display'
}
/**
 * @property shouldUseTheme - You can use this prop if you want the text to use the theme value not the css variable.
 */
export const Title1 = styled.h1<TitleProps>`
  font-family: ${({ theme, font }) => {
    if (font === 'secondary') {
      return theme.fonts.secondary
    }
    if (font === 'display') {
      return theme.fonts.display
    } else {
      return theme.fonts.primary
    }
  }};
  font-weight: 900;
  line-height: 1.5;
  color: ${props =>
    props.shouldUseTheme
      ? props.theme.colors.headings.default
      : 'var(--color-heading)'};
  transition-property: color;
  transition-duration: 1.5s;
  font-size: 3.5rem;
  ${({ centered }) => centered && 'text-align: center'};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 3rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 2.75rem;
  }
  text-size-adjust: 70%;
`

export const Heading = styled(Title1).attrs({ as: 'h2' })`
  font-size: 2.75rem;
  font-weight: 600;

  text-size-adjust: 80%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 2rem;
  }
`

export const Heading2 = styled(Title1).attrs({ as: 'h3' })<{
  centered?: boolean
}>`
  line-height: 1.75;
  text-size-adjust: 80%;

  font-size: 2rem;
  ${({ centered }) => centered && 'text-align: center'};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.75rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.5rem;
  }
`

export const Heading3 = styled(Heading2).attrs({ as: 'h3' })<{
  centered?: boolean
}>`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.75;
  ${({ centered }) => centered && 'text-align: center'};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.25rem;
  }
`

export const FooterHeader = styled.h2`
  font-family: 'neue-haas-unica', serif;
  font-weight: 700;
  font-size: 0.8rem;
  text-size-adjust: 80%;

  text-transform: uppercase;
  letter-spacing: 0.1625rem;
  color: var(--color-accent);
`
