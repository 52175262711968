import * as React from 'react'

/** Creates a trap focus stack between two HTMLElements
 *  If user is tabbing forward and the tail element is active,
 * focuses on the head element.
 * If user is tabbing backwards and the head element is active,
 * focuses on the tail element.
 *  Useful for trapping focus in modal dialogs.
 *  @example
 * // Traps focus between headNode and tailNode
 *  useTrapFocus(headNode, tailNode);
 */

export const useTrapFocus = <
  HeadType extends HTMLElement = HTMLElement,
  TailType extends HTMLElement = HTMLElement
>(
  headNode: React.RefObject<HeadType>,
  tailNode: React.RefObject<TailType>
): void => {
  const handleKeyDown = (e: KeyboardEvent) => {
    const isTabbingForward = e.key === 'Tab' && !e.shiftKey
    const isTabbingBackward = e.key === 'Tab' && e.shiftKey

    const tailIsFocused = document.activeElement === tailNode.current
    const headIsFocused = document.activeElement === headNode.current

    if (isTabbingForward && tailIsFocused) {
      e.preventDefault()
      headNode.current?.focus()
      return
    }

    if (isTabbingBackward && headIsFocused) {
      e.preventDefault()
      tailNode.current?.focus()
      return
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [headNode.current, tailNode.current])
}

export default useTrapFocus
