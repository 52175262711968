import React from 'react'
import { IconProps } from './types'

const BigLemonLogo: React.FC<IconProps> = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='Layer_1'
      data-name='Layer 1'
      viewBox='0 0 155 31.68'
      fill='var(--color-logo, #ffffff)'
      role='img'
      style={{ transition: 'fill 1.5s ease-in-out' }}
    >
      <title>Big Lemon</title>
      <defs />
      <path
        d='M15.55 20.35c0-2.45-1.33-4.1-4.43-4.1H8.85v8.05h2.27c3.1 0 4.43-1.65 4.43-3.95M8.85 7v7.64h2.39c2.51 0 3.54-1.74 3.54-3.84S13.75 7 11.24 7zm12.61 13.2c0 3.54-3.28 5.76-8.57 5.76H1.5l1.56-1.77V7.06L1.5 5.29h11.34c4.69 0 7.61 2 7.61 5.14 0 2.39-1.41 4.07-3.95 4.9 3.18.65 5 2.21 5 4.87M42.77 16.16c0-2.28-.92-4.28-2.25-4.28-1.15 0-1.8 1.44-1.8 3.33 0 2.28 1 4.28 2.25 4.28s1.8-1.44 1.8-3.33m-.36 11h-5.34a3.18 3.18 0 00-.77 2.1c0 1.65 1.12 2.74 3.87 2.74s4.34-1.27 4.34-3c0-1.15-.68-1.83-2.1-1.83m7.38-13.49l-3.13-1.18A4.23 4.23 0 0148 15.69c0 3.83-4.07 5.07-7.2 5.07a10.86 10.86 0 01-3.87-.68 2 2 0 00-.93 1.54c0 .71.44 1.15 1.3 1.15h9.83a4.84 4.84 0 012.62 4.31c0 3.28-3 6.26-10.21 6.26-4.25 0-6.94-1.24-6.94-3.51 0-1.19.71-2.19 2.25-2.93a3.9 3.9 0 01-1.78-3.36 4.83 4.83 0 012.54-4.11 4.22 4.22 0 01-2.06-3.74c0-3.84 4.07-5.08 7.2-5.08a11 11 0 013.63.59l5.37-.53z'
        className='cls-1'
        transform='translate(-1.5 -1.66)'
      />
      <path
        d='M66.44 17.38v5.05h-.03v1.65H49.67l1.57-1.77V5.19l-1.57-1.77h8.92l-1.57 1.77v17.24h6.14l3.28-5.05z'
        className='cls-1'
      />
      <path
        d='M74.67 15.74h4.66c0-2.45-.67-3.8-2-3.8s-2.48 1.38-2.63 3.8m9.71 5l.15.15c-1.06 3.66-3.48 5.4-7 5.4a7.63 7.63 0 01-8-8c0-4.78 3.49-7.67 8.33-7.67 4.19 0 6.76 2.15 6.82 6.34h-10c.15 3.58 1.92 5.38 4.87 5.38a8 8 0 004.87-1.63M114.08 26h-8.62l1.54-1.81V15.8c0-1.71-.56-2.27-1.74-2.27a3.94 3.94 0 00-2.48.92v9.74l1.59 1.81h-8.62l1.56-1.77V15.8c0-1.71-.56-2.27-1.74-2.27a3.91 3.91 0 00-2.48.92v9.74L94.65 26H86l1.57-1.77v-9l-1.45-1.68 6.94-3-.44 2.83.15.15a7.94 7.94 0 015.87-2.8c1.77 0 3.37.62 3.93 2.71a7.88 7.88 0 015.78-2.71c2.22 0 4.14 1 4.14 4.48v9.1zM126.71 19.05c0-4.28-1-7.08-2.95-7.08s-2.45 2.42-2.45 5.84c0 4.28 1.06 7.09 3 7.09s2.42-2.43 2.42-5.85m-11.25-.62c0-5.37 4.34-7.82 8.57-7.82s8.56 2.45 8.56 7.82-4.34 7.82-8.56 7.82-8.57-2.45-8.57-7.82M152.87 26h-8.62l1.56-1.77V15.8c0-1.74-.77-2.27-2-2.27a4.42 4.42 0 00-2.62.92v9.74l1.55 1.81h-8.62l1.57-1.77v-9l-1.45-1.68 6.94-3-.45 2.83.15.15a7.75 7.75 0 016-2.8c2.25 0 4.4 1 4.4 4.48v9.1zM151.48 9.38a2.51 2.51 0 112.52 2.51 2.51 2.51 0 01-2.51-2.51m4.76 0a2.25 2.25 0 10-2.25 2.28 2.27 2.27 0 002.25-2.28m-.61 1.24c-1 .07-1.4-.2-1.69-1.24a.52.52 0 00.45-.56c0-.35-.17-.51-.41-.51h-.26v2l.26.3h-1.39l.25-.3V8.39l-.25-.3h1.53c.65 0 1.14.16 1.14.67a.68.68 0 01-.47.64 2.4 2.4 0 00.83 1.22'
        className='cls-1'
        transform='translate(-1.5 -1.66)'
      />
      <path
        d='M21.49 24.14h8.64l-1.57-1.78V8.57l-6.95 3.05 1.45 1.69v9.05l-1.57 1.78z'
        className='cls-1'
      />
      <path
        d='M24.32 4.32A3.88 3.88 0 0024 6.56 2.61 2.61 0 0024.57 8a1.85 1.85 0 00.07.85.46.46 0 00.56.32 1.68 1.68 0 00.8-.41 2.64 2.64 0 001.51-.17 3.85 3.85 0 001.77-1.41 4 4 0 00.23-.34l.18-.36A4 4 0 0030 4.24a2.62 2.62 0 00-.6-1.39 1.73 1.73 0 00-.07-.85.45.45 0 00-.56-.32A1.73 1.73 0 0028 2a2.6 2.6 0 00-1.5.17 3.92 3.92 0 00-1.78 1.41 4 4 0 00-.22.42 2 2 0 00-.18.36'
        className='cls-1'
        transform='translate(-1.5 -1.66)'
      />
    </svg>
  )
}

export default BigLemonLogo
