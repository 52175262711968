import { useStaticQuery, graphql } from 'gatsby'

import { SiteData } from '../API'

/**
 * Get sites meta data found in gatsby-config
 * @returns
 */
const useSiteMetaData = () => {
  const data: SiteData = useStaticQuery(graphql`
    query SiteMeta {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
          twitterUsername
        }
      }
    }
  `)
  return data
}

export default useSiteMetaData
