import * as React from 'react'
import { ArrowRight } from '../IconComponent'
import { Caption } from '@components/Typography'
import { EdgyBox } from '../SquaredLinkComponent'
import styled from 'styled-components'
// same styles as SquaredLink

type SquaredLinkComponentProps = {
  label: string
  icon?: React.ReactNode
  className?: string
  id?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const SquaredButtonComponent: React.FC<SquaredLinkComponentProps> = ({
  label,
  icon,
  className,
  ...props
}) => (
  <Button {...props}>
    <EdgyBox className={className}>
      <ButtonText>{label?.toUpperCase()}</ButtonText>
      <ArrowRight />
    </EdgyBox>
  </Button>
)

const Button = styled.button`
  outline: none;
  background: transparent;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: max-content;
  border: none;
`

const ButtonText = styled(Caption)`
  color: var(--color-primary);
  text-size-adjust: 80%;
`

export default SquaredButtonComponent
