import { Heading2, Paragraph } from '@components/Typography'
import React from 'react'
import CookieConsent from 'react-cookie-consent'
import styled from 'styled-components'
// @ts-expect-error
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import SpacerComponent from '@components/SpacerComponent'
// @ts-expect-error
import LemonCookie from './LemonCookieinCircle.png'
import useIsTablet from '@hooks/useIsTablet'

const StyledCookieConsent = styled(CookieConsent)``

const Wrapper = styled.div`
  max-height: 60%;
`

export const CookieBanner = () => {
  const isMobile = useIsTablet()

  const ids = {
    acceptButton: 'cookie-policy-accept-button',
    title: 'cookie-policy-title',
    description: 'cookie-policy-description',
  }

  const isClient = typeof document !== 'undefined'

  React.useEffect(() => {
    setTimeout(() => {
      const acceptButton = isClient && document.getElementById(ids.acceptButton)
      if (acceptButton) {
        acceptButton.focus()
      }
    }, 0)
  }, [])

  return (
    <Wrapper
      role='dialog'
      aria-labelledby={ids.title}
      aria-describedby={ids.description}
    >
      <StyledCookieConsent
        enableDeclineButton
        style={{
          background: undefined,
          display: 'block',
          maxHeight: '60%',
          overflow: 'scroll',
          paddingBottom: '42px',
          paddingTop: '42px',
          height: 'max-content',
        }}
        declineButtonClasses='cookie-btn cookie-btn-decline'
        // Setting the styles as undefined allows us to use the classes
        declineButtonStyle={{
          borderRadius: undefined,
          'background-color': undefined,
          background: undefined,
          padding: undefined,
          color: undefined,
        }}
        onAccept={() => initializeAndTrack(window.location)}
        // Setting the styles as undefined allows us to use the classes
        buttonStyle={{
          borderRadius: undefined,
          background: undefined,
          padding: undefined,
          color: undefined,
        }}
        buttonClasses='cookie-btn'
        containerClasses='cookie-container'
        contentClasses='cookie-content'
        buttonId={ids.acceptButton}
        location='bottom'
        buttonText='Sounds good'
        cookieName='gatsby-gdpr-google-tagmanager'
        expires={150}
      >
        <Main>
          <Body id={ids.description}>
            <WhiteTitle id={ids.title}>Hi there.. 👋</WhiteTitle>
            <SpacerComponent bottom='sm' />
            Big Lemon uses cookies to analyse traffic and enhance your
            experience whilst on our website. By agreeing, you’re telling us
            you’re happy with us storing cookies in your browser and, where
            applicable, processing your related personal data for these
            purposes. If you need more info, head on over to our{' '}
            <a
              href='https://www.iubenda.com/privacy-policy/99385969/cookie-policy'
              aria-label='Go to our cookie policy'
            >
              cookie policy
            </a>
            .
          </Body>
          {!isMobile && <Circle src={LemonCookie} aria-hidden='true' />}
        </Main>
      </StyledCookieConsent>
    </Wrapper>
  )
}
const Main = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4rem;
  row-gap: ${({ theme }) => theme.spacing.sm};
  width: 100%;
`
const Body = styled(Paragraph)`
  color: ${props => props.theme.colors.text};
  flex-basis: 65%;
  flex-shrink: 1;
  flex-grow: 1;
  max-width: 1000px;
  a {
    color: #fff;
  }
`

const WhiteTitle = styled(Heading2)`
  color: ${props => props.theme.colors.text};
`

const Circle = styled.img`
  width: 343px;
  height: 343px;
  border-radius: 50%;
`
