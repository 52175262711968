import * as React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components/macro'

export const Highlight: React.FC<{ color?: 'yellow' }> = props => {
  const hasAnimated = React.useRef(false)
  const [highlightWidth, setHighlightWidth] = React.useState<'0%' | '100%'>(
    '0%'
  )

  const { ref, inView } = useInView({ threshold: 1 })

  React.useEffect(() => {
    const shouldAnimate = inView && !hasAnimated.current
    if (shouldAnimate) {
      hasAnimated.current = true
      setHighlightWidth('100%')
    }
  }, [inView])

  return (
    <HighlightText
      ref={ref}
      color={props.color}
      highlightWidth={highlightWidth}
    >
      {props.children}
    </HighlightText>
  )
}

const HighlightText = styled.span<{
  color?: 'yellow'
  highlightWidth: '0%' | '100%'
}>`
  color: ${props =>
    props.color === 'yellow'
      ? props.theme.colors.yellow.default
      : 'var(--color-highlight-text)'};
  -webkit-transition: color 3s ease;
  -moz-transition: color 3s ease;
  -o-transition: color 3s ease;
  transition: color 3s ease, background-size 3s ease;
  background-image: linear-gradient(
    to top,
    var(--color-highlight-background) 50%,
    transparent 50%
  );
  background-size: ${({ highlightWidth }) => highlightWidth};
  background-repeat: no-repeat;
`
