import * as React from 'react'

/** Detects whether user is tabbing through document as opposed
 * to navigating with mouse. If user tabs, set to true. If user
 * mouse clicks, set to false. Returns current boolean tabbing value.
 * Also sets document.body.dataset.tabbing to String(tabbingValue).
 */

export const useIsTabbing = () => {
  const [tabbing, setTabbing] = React.useState(false)

  const handleTabPress = (e: KeyboardEvent) => {
    if (e.key === 'Tab' && !tabbing) setTabbing(true)
  }

  const handleMouseDown = () => {
    if (tabbing) setTabbing(false)
  }

  React.useEffect(() => {
    if (!document) return
    document.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('keydown', handleTabPress)
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('keydown', handleTabPress)
    }
  }, [tabbing])

  React.useEffect(() => {
    document.body.dataset.tabbing = String(tabbing)
  }, [tabbing])

  return tabbing
}
export default useIsTabbing
