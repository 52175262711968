import * as React from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

type StaggerAnimationProps = {
  threshold?: number
}

const StaggerAnimation: React.FC<StaggerAnimationProps> = ({
  children,
  threshold,
}) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({ threshold: threshold })

  React.useEffect(() => {
    if (inView) {
      controls.start('visible')
    } else {
      controls.start('hidden')
    }
  }, [controls, inView])

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.2,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren',
      },
    },
  }
  return (
    <motion.div ref={ref} animate={controls} initial='hidden' variants={list}>
      {children}
    </motion.div>
  )
}

export default StaggerAnimation
