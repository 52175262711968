import styled from 'styled-components/macro'

/**
 * @property shouldUseTheme - You can use this prop if you want the text to use the theme value not the css variable.
 */
export const Paragraph = styled.p<{
  size?: 'xs' | 'sm' | 'md' | 'lg'
  centered?: boolean
  hasHover?: boolean
  shouldUseTheme?: boolean
}>`
  font-family: 'neue-haas-unica', serif;
  font-weight: ${({ size }) => {
    if (size === 'xs') return 300
    // default === 'md'
    return 400
  }};
  font-size: ${({ size }) => {
    if (size === 'xs') return '1rem'
    if (size === 'sm') return '1.125rem'
    if (size === 'md') return '1.25rem'
    if (size === 'lg') return '1.5rem'
    // default === 'md'
    return '1.25rem'
  }};
  color: ${({ shouldUseTheme, theme }) =>
    shouldUseTheme ? theme.colors.text : 'var(--color-text)'};
  transition-property: color;
  transition-duration: 1s;
  line-height: ${({ size }) => {
    if (size === 'xs') return 1.75
    if (size === 'sm') return 1.75
    if (size === 'md') return 2
    if (size === 'lg') return 2
    // default === 'md'
    return 2
  }};
  ${({ centered }) => centered && 'text-align: center'};

  &:hover {
    color: ${({ hasHover }) => hasHover && 'var(--color-accent)'};
  }
  & b {
    font-weight: 700;
  }
  text-size-adjust: 80%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ size }) => {
      if (size === 'xs') return '1rem'
      if (size === 'sm') return '1.1rem'
      if (size === 'md') return '1.15rem'
      if (size === 'lg') return '1.25rem'
      // default === 'md'
      return '1.15rem'
    }};
  }
`
