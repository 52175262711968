import * as React from 'react'
import styled, { css } from 'styled-components/macro'

// Components
import {
  Facebook,
  LinkedIn,
  Twitter,
  Instagram,
} from '@components/IconComponent'

export type SocialIconId = 'linkedin' | 'twitter' | 'facebook' | 'instagram'

const SocialIcon: React.FC<{
  icon: SocialIconId
  href: string
  ariaLabel: string
  reduceMargins?: boolean
}> = ({ href, ariaLabel, icon }) => (
  <Li>
    <A
      href={href}
      aria-label={ariaLabel}
      target='_blank'
      rel='noopener noreferrer'
    >
      {(() => {
        if (icon === 'facebook') return <StyledFacebook />
        if (icon === 'linkedin') return <StyledLinkedIn />
        if (icon === 'twitter') return <StyledTwitter />
        if (icon === 'instagram') return <StyledInstagram />
        throw new Error('No icon passed to SocialIcon.')
      })()}
    </A>
  </Li>
)

const Li = styled.li`
  place-items: center;
`

const A = styled.a`
  display: grid;
  place-items: center;
`

const SocialIconStyles = css`
  transition: fill 0.2s ease-in-out;
  width: ${({ theme }) => theme.spacing.xs};
  height: ${({ theme }) => theme.spacing.xs};
  fill: var(--color-text);
  &:hover {
    fill: var(--color-accent);
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const StyledTwitter = styled(Twitter)`
  ${SocialIconStyles}
`

const StyledLinkedIn = styled(LinkedIn)`
  ${SocialIconStyles}
`

const StyledFacebook = styled(Facebook)`
  ${SocialIconStyles}
`

const StyledInstagram = styled(Instagram)`
  ${SocialIconStyles}
`

export default SocialIcon
