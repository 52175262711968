import { useEffect, useState } from 'react'

export const useIsTablet = () => {
  const isBrowser = typeof window !== 'undefined'
  const [isTablet, setIsTablet] = useState<boolean>(false)

  useEffect(() => {
    updateIsTablet()
    window.addEventListener('resize', updateIsTablet)

    return () => {
      window.removeEventListener('resize', updateIsTablet)
    }
  }, [isTablet])

  const updateIsTablet = () => {
    if (isBrowser) {
      const tempIsTablet = window.innerWidth < 960
      if (tempIsTablet !== isTablet) setIsTablet(tempIsTablet)
    }
  }

  return isTablet
}

export default useIsTablet
