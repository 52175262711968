import * as React from 'react'
import styled from 'styled-components/macro'

export const Separator = styled.div`
  width: 100%;
  border: none;
  height: 1px;
  background-color: var(--color-spacer);
`

const SeparatorComponent: React.FC<{ className?: string }> = ({
  className,
}) => <Separator className={className} aria-hidden='true' />

export default SeparatorComponent
