import * as React from 'react'

export const useScrollOnFocus = (condition: boolean) => {
  React.useEffect(() => {
    const scrollFocused = () => {
      const focused = document.activeElement
      // scrolls to vertical center
      condition && focused?.scrollIntoView({ block: 'center' })
    }

    document.body.addEventListener('focusin', scrollFocused)

    return () => document.body.removeEventListener('focusin', scrollFocused)
  }, [condition])
}
