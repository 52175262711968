import * as React from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import AnimationContainer from './AnimationContainer'

type FadeInProps = {
  threshold?: number
  direction?: 'left' | 'right'
  className?: string
}

const FadeIn: React.FC<FadeInProps> = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: props.threshold,
    triggerOnce: true,
  })

  React.useEffect(() => {
    if (inView) {
      controls.start('fadeIn')
    } else {
      controls.start('fadeOut')
    }
  }, [controls, inView])

  return (
    <AnimationContainer
      ref={ref}
      animate={controls}
      initial='fadeOut'
      className={props.className}
      style={{ width: '100%', height: '100%' }}
      variants={{
        fadeIn: {
          scale: [1, 1],
          opacity: [0, 1],
          y: [5, 0],
          transition: {
            duration: 0.5,
            ease: 'easeInOut',
            times: [0, 1],
            delay: 0.5,
          },
        },
        fadeOut: {
          opacity: 0,
          y: -5,
          transition: {
            duration: 0.5,
            ease: 'easeInOut',
            times: [0, 1],
          },
        },
      }}
    >
      {props.children}
    </AnimationContainer>
  )
}

export default FadeIn
