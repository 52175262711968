import { useLocation } from '@reach/router'
import * as React from 'react'
import styled from 'styled-components/macro'
import { Paragraph } from '../Typography'

const AddressComponent: React.FC = props => {
  const { pathname } = useLocation()
  console.log('pathname',pathname)
  return (
    <Address><Paragraph size='xs' style={{ fontStyle: 'italic' }}>
        We are a fully remote team
      </Paragraph>
      <Paragraph size='xs' style={{ fontStyle: 'italic' }}>
        with an ecosystem that is
      </Paragraph>
      <Paragraph size='xs' style={{ fontStyle: 'italic' }}>
        UK-wide.
      </Paragraph>
      <br />
      
      {pathname === 'about-us' && (
        <>
          <Paragraph size='xs'>
            Registered address: 23 Beeches Park, Boughrood, LD3 0YJ
          </Paragraph>
          <br />
        </>
      )}

      <a href='tel:+442921679025'>
        <Paragraph size='xs' hasHover={true}>
          029 2167 9025
        </Paragraph>
      </a>
      <br />
      <a href='mailto:hello@biglemon.co.uk'>
        <Paragraph size='xs' hasHover={true}>
          hello@biglemon.co.uk
        </Paragraph>
      </a>
    </Address>
  )
}

const Address = styled.address`
  & a {
    text-decoration: none;
  }
`

export default AddressComponent
