import { CreatePropertyMapReturn } from '@src/utils/createThemePropertyMap'
import { createGlobalStyle } from 'styled-components/macro'
import reset from 'styled-reset'
import { theme, spacer } from '../themes'
import { css } from 'styled-components'

export const tabbingFocusStyles = css`
  * {
    &:focus {
      [data-tabbing='false'] & {
        outline: none;
      }
      [data-tabbing='true'] & {
        outline-offset: 6px;
        outline: 3px solid
          var(--color-focus-ring, ${theme.colors.yellow.default});
      }
    }
  }
`

// See https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/

export const prefersReducedMotion = css`
  @media screen and (prefers-reduced-motion: reduce), (update: slow) {
    * {
      animation-duration: 0.001ms !important;
      animation-iteration-count: 1 !important; /* Hat tip Nick/cssremedy (https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/#comment-1700170) */
      transition-duration: 0.001ms !important;
      transform: none !important;
    }
  }
`

export const GlobalStyle = createGlobalStyle<CreatePropertyMapReturn>`
    ${reset}
    html {
      --color-text: ${props => props['--color-text']};
      --color-background:${props => props['--color-background']};
      --color-heading: ${props => props['--color-heading']};
      --color-highlight: ${props => props['--color-highlight-background']};
      --color-spacer: ${spacer.light};
      --color-logo: ${theme.colors.navigation.logo?.light};
      --color-certifications-icon: ${theme.colors.certificationsLogo.light};
      --color-nav-button-shadow: #FAD6D1;
      background-color: var(--color-background);
      color: var(--color-text);
      scroll-behavior: smooth;
      box-sizing: border-box;
      transition: background-color 1.5s ease-in-out;
      -webkit-transition: background-color 1.5s ease;
    }
    html, body {
      overflow-x: hidden;
    }
    *, *:before, *:after {
    box-sizing: inherit;
}
 ${tabbingFocusStyles}
 ${prefersReducedMotion}
  .visually-hidden {
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    white-space: nowrap;
  }

  .cookie-btn {
    padding: 0.75rem 1.5rem;
    border: none;
     width: 200px;
     border-radius: 0.1rem;
     font-family: ${({ theme }) => theme.fonts.secondary};

    font-weight: 700;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 2.8px;
    background-color: ${props => props.theme.colors.coral.default};
    color: ${({ theme: { colors } }) => colors.button.text};

  }

  .cookie-btn-decline {
    background-color: ${props => props.theme.colors.purple.default};
    color: ${props => props.theme.colors.blue.light};
  }

  .cookie-container {

    background-color: ${props => props.theme.colors.darkPurple.light};
    font-family: ${({ theme }) => theme.fonts.secondary};
    padding: ${props => props.theme.padding.lg};
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      padding: 2rem;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 1rem;
    }
  }

  .cookie-content {
    display: flex;
  }

  a {
    display: inline-block;
  }

 
`
