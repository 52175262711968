import { useEffect, useState } from 'react'

export const useIsMobile = () => {
  const isBrowser = typeof window !== 'undefined'
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    updateIsMobile()
    window.addEventListener('resize', updateIsMobile)

    return () => {
      window.removeEventListener('resize', updateIsMobile)
    }
  }, [isMobile])

  const updateIsMobile = () => {
    if (isBrowser) {
      const tempIsMobile = window.innerWidth < 720
      if (tempIsMobile !== isMobile) setIsMobile(tempIsMobile)
    }
  }

  return isMobile
}

export default useIsMobile
