import * as React from 'react'
import styled from 'styled-components/macro'

type TextColors = 'blue' | 'yellow'

export const VerticalText = styled.p<{ color?: TextColors }>`
  font-family: 'neue-haas-grotesk-display', serif;
  transform: scale(-1);
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  writing-mode: vertical-lr;
  flex-basis: max-content;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 0.85rem;
  font-weight: 600;
  color: var(--color-accent);
  transition-property: color;
  transition-duration: 1s;
  text-align: right;
  margin-top: 1.25rem;
`
const VerticalSpacer = styled.span`
  width: 0.0625rem;
  background-color: var(--color-spacer);
  height: 100%;
  transition-property: background-color;
  transition-duration: 1s;
`
const VerticalHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.base};
`

export const VerticalHeading: React.FC<{
  className?: string
  color?: TextColors
  hasVerticalSpacer?: boolean
}> = ({ hasVerticalSpacer = true, ...props }) => (
  <VerticalHeadingContainer className={props.className}>
    <VerticalText color={props.color}>{props.children}</VerticalText>
    {hasVerticalSpacer && <VerticalSpacer aria-hidden='true' />}
  </VerticalHeadingContainer>
)
