import React, { useState } from 'react'
import styled, { css, useTheme } from 'styled-components/macro'

// Components
import SquaredButtonComponent from '@components/SquaredButtonComponent'
import { Caption, Paragraph } from '@components/Typography'

// Hooks
import useIsMobile from '@hooks/useIsMobile'
import SpacerComponent from '@components/SpacerComponent'

const FormContactComponent: React.FC<{
  isMobile?: boolean
  submitId?: string
}> = props => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    message: '',
    'bot-field': '',
  })

  const [successMessage, setSuccessMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const onSubmit = (e: any) => {
    e.preventDefault()
    setSuccessMessage('')
    setErrorMessage('')

    const formValues = new URLSearchParams({
      ...values,
      'form-name': 'Contact',
    }).toString()

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: formValues,
    })
      .then(() => {
        setSuccessMessage('Thanks for the message! We’ll be in touch soon.')
        setValues({
          name: '',
          email: '',
          message: '',
          'bot-field': '',
        })
      })
      .catch(() => {
        setErrorMessage('Something went wrong, please try again later.')
      })
  }

  const updateName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, name: e.target.value })
  const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, email: e.target.value })
  const updateMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setValues({ ...values, message: e.target.value })
  const updateBotField = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValues({ ...values, 'bot-field': e.target.value })

  const theme = useTheme()
  const isMobile = useIsMobile()

  return (
    <FormContact
      name='Contact'
      onSubmit={onSubmit}
      isMobile={isMobile}
      data-netlify='true'
      netlify-honeypot='bot-field'
    >
      <InputField>
        <Label
          as='label'
          id='form-contact-label-name'
          htmlFor='form-contact-input-name'
        >
          NAME
        </Label>
        <Input
          name='name'
          id='form-contact-input-name'
          aria-labelledby='form-contact-label-name'
          placeholder='Your name'
          type='text'
          required
          autoComplete='name'
          value={values.name}
          onChange={updateName}
        />
      </InputField>
      <InputField>
        <Label
          as='label'
          id='form-contact-label-email'
          htmlFor='form-contact-input-email'
        >
          EMAIL
        </Label>
        <Input
          name='email'
          placeholder='Your email'
          type='email'
          autoComplete='email'
          id='form-contact-input-email'
          aria-labelledby='form-contact-label-email'
          required
          value={values.email}
          onChange={updateEmail}
        />
      </InputField>
      <TextAreaField>
        <label id='form-contact-label-message' className='visually-hidden' />
        <TextArea
          isMobile={isMobile}
          name='message'
          placeholder='Your message to us'
          id='form-contact-input-message'
          aria-labelledby='form-contact-label-message'
          required
          value={values.message}
          onChange={updateMessage}
        />
      </TextAreaField>
      <input
        type='hidden'
        name='bot-field'
        value={values['bot-field']}
        onChange={updateBotField}
      />

      {successMessage ? (
        <SuccessMessageStyle id='successMessage' size='sm'>
          {successMessage}
        </SuccessMessageStyle>
      ) : null}
      {errorMessage ? (
        <ErrorMessageStyle id='errorMessage' size='sm'>
          {errorMessage}
        </ErrorMessageStyle>
      ) : null}
      <SpacerComponent top={'xs'} />
      <SquaredButtonComponent
        disabled={!!successMessage}
        id={props.submitId ?? 'contactFormSubmit'}
        label='submit'
      />
    </FormContact>
  )
}

const FormContact = styled.form<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${({ theme }) => theme.spacing.xs};
  width: 100%;
`

const InputFieldBaseStyles = css`
  display: flex;
  border-style: solid;
  border-width: 0.05rem;
  border-color: var(--color-spacer);
  border-radius: 0.1rem;
  padding: 0.75rem 1rem;
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.purple.default};
  }
  &:focus-within {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.purple.default};
    color: ${({ theme }) => theme.colors.purple.default};
  }
`

const InputField = styled.div`
  ${InputFieldBaseStyles}
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
`

const TextAreaField = styled.div`
  ${InputFieldBaseStyles}
  flex-direction: column;
  justify-content: stretch;
`

const Label = styled(Caption).attrs({ as: 'label' })`
  background: transparent;
  line-height: 1.25;
  color: inherit;
`

const InputBaseStyles = css`
  flex-grow: 1;
  width: 100%;
  background: transparent;
  font-family: ${({ theme }) => theme.fonts.secondary};
  resize: none; // for text area
  color: ${({ theme }) => theme.colors.text};
  ::placeholder {
    color: var(--color-text);
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: 1rem;
    text-align: left;
    line-height: 1.5; // fixes weird alignment in text area due to row flex
    opacity: 50%;
  }
  border: none;
  outline: none;
`

const Input = styled.input<{ isMobile?: boolean }>`
  ${InputBaseStyles};
`

const TextArea = styled.textarea<{ isMobile?: boolean }>`
  ${InputBaseStyles};

  min-height: ${({ theme, isMobile }) =>
    isMobile ? theme.spacing.xl : theme.spacing.lg};
`

const SuccessMessageStyle = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.success};
`
const ErrorMessageStyle = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.error};
`

export default FormContactComponent
