import * as React from 'react'
import { Paragraph } from '../Typography'
import { Link } from 'gatsby'
import styled from 'styled-components'

const resources = [
  {
    label: 'Big Lemon Developer Blog',
    href: 'https://dev.biglemon.co.uk/',
  },
  {
    label: 'Big Lemon Impact Report 2022/2023',
    href: '/impact-report',
  },
  {
    label: 'How to Create an App',
    href: '/how-to-create-an-app',
  },
  {
    label: 'What is Tech for Good?',
    href: '/what-is-tech-for-good',
  },
  {
    label: 'What is Co-production?',
    href: '/what-is-co-production',
  },
]

const ResourceList: React.FC = props => {
  return (
    <ResourceListWrapper>
      {resources.map(study => (
        <li>
          <Link to={study.href}>
            <Paragraph size='xs' hasHover={true}>
              {study.label}
            </Paragraph>
          </Link>
        </li>
      ))}
    </ResourceListWrapper>
  )
}
export default ResourceList

const ResourceListWrapper = styled.ul`
  & li {
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
  & a {
    text-decoration: none;
  }
`
