import * as React from 'react'
import { theme } from '../../themes'
import styled from 'styled-components'
import useIsMobile from '@hooks/useIsMobile'

const { spacing } = theme

export type PaddingValue = keyof typeof spacing | '0'

type SpacerComponentProps = {
  top?: PaddingValue
  bottom?: PaddingValue
  left?: PaddingValue
  right?: PaddingValue
  horizontal?: PaddingValue
  vertical?: PaddingValue
  custom?: string
  ref?: any
}

/** Spacer component.
 * Props: 'top', 'bottom', 'right', or 'left', 'horizontal', 'vertical', 'custom'.
 * All but custom accept a spacing value from the theme. 
 * Custom is an escape hatch. It accepts for any string which is directly passed as the padding value.
 * Note, when child of a Grid it will span all available columns
 * @example
 *
 *
 * <Spacer top='lg' bottom='lg'>
 *  ...
 * </Spacer>
 *
 * // css output: padding: '3.5rem 0 3.5rem 0'
 *
 * // Horizontal and vertical take precedence over individual units
 *
 * <Spacer top='lg' bottom='lg' vertical="xs">
 *  ...
 * </Spacer>
 *
 *  // css output: padding: '1rem 0 1rem 0'
 * 
 * // Custom takes precedence over all other values
 *
 * <Spacer custom="0.5rem 0.25rem 0.25rem 0.5rem">
 *  ...
 * </Spacer>
 *
 
 *
 */

const SpacerComponent: React.FC<SpacerComponentProps> = ({
  top = '0',
  right = '0',
  left = '0',
  bottom = '0',
  vertical,
  horizontal,
  children,
  custom,
  ref,
}) => {
  const isMobile = useIsMobile()

  let spacing = { ...theme.spacing, mobile: undefined, '0': '0' }

  if (isMobile) {
    spacing = { ...theme.spacing.mobile, mobile: undefined, '0': '0' }
  }

  const values = {
    top: vertical ? spacing[vertical] : spacing[top],
    right: horizontal ? spacing[horizontal] : spacing[right],
    left: horizontal ? spacing[horizontal] : spacing[left],
    bottom: vertical ? spacing[vertical] : spacing[bottom],
  }

  const padding = `${values.top} ${values.right} ${values.bottom} ${values.left}`

  return (
    <Spacer ref={ref} padding={custom ?? padding}>
      {children}
    </Spacer>
  )
}

const Spacer = styled.div<{ padding: string }>`
  padding: ${({ padding }) => padding};
  grid-column: 1 / -1;
`

export default SpacerComponent
