import styled from 'styled-components'

export const Quote = styled.p<{
  font?: 'primary' | 'secondary' | 'display'
  size?: 'sm' | 'md'
  center?: boolean
}>`
  font-family: ${({ theme, font }) => {
    if (font === 'secondary') {
      return theme.fonts.secondary
    }
    if (font === 'display') {
      return theme.fonts.display
    } else {
      return theme.fonts.primary
    }
  }};
  font-size: ${({ size }) => (size === 'sm' ? '1.5rem' : '2rem')};
  color: var(--color-heading);
  line-height: 1.75;
  font-weight: 600;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ size }) => (size === 'sm' ? '1.3rem' : '1.8rem')};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ size }) => (size === 'sm' ? '1.3rem' : '1.5rem')};
  }
`

export const Author = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: var(--color-highlight-text);
  transition: color 3s ease;
  width: fit-content;
  font-weight: bold;
  line-height: 1.5;
  font-size: 1.5rem;
  background-image: linear-gradient(
    to top,
    var(--color-highlight-background) 50%,
    transparent 50%
  );
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.75rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.5rem;
  }
`
