import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
// Components
import {
  Cross,
  BigLemonLogo,
  BurgerMenu as BurgerIcon,
} from '@components/IconComponent'
import SquaredLinkComponent from '@components/SquaredLinkComponent'
import { Caption } from '@components/Typography'

//Hooks
import useIsTablet from '@hooks/useIsTablet'
import useConditionalFocus from '@hooks/useConditionalFocus'
import useTrapFocus from '@hooks/useTrapFocus'
import { AnimatePresence, motion } from 'framer-motion'

type NavItemProps = {
  href: string
  label: string
  onClick: () => void
}

const quoteLinkLabel = 'Get your quote'

const HeaderLayout: React.FC = () => {
  const isTablet = useIsTablet()
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)

  const onMenuClick = () => {
    setShowMobileMenu(true)
  }
  const onBackClick = () => {
    setShowMobileMenu(false)
  }

  return (
    <>
      <HeaderDesktop show={!isTablet} onBackClick={onBackClick} />
      <HeaderMobile menuIsOpen={showMobileMenu} onMenuClick={onMenuClick} />
      {isTablet && (
        <MobileModal
          showMobileMenu={showMobileMenu}
          onBackClick={onBackClick}
        />
      )}
    </>
  )
}

const HeaderDesktop: React.FC<{ show: boolean; onBackClick: () => void }> =
  props => {
    return (
      <VisibilityController isDesktop>
        <HeaderWrapper>
          <LogoWrapper>
            <HomeLink to='/'>
              <BigLemonLogo />
            </HomeLink>
          </LogoWrapper>
          <NavItems onItemClick={props.onBackClick} />
          <CTAWrapper>
            <SquaredLinkComponent
              label={quoteLinkLabel}
              to='/quote'
              className='quote-button'
            />
          </CTAWrapper>
        </HeaderWrapper>
      </VisibilityController>
    )
  }

/* To stop popping in due to SSR, we determine the visibility of the mobile / desktop nav with
  css rather than js  */

const VisibilityController = styled.div<{ isDesktop: boolean }>`
  display: ${props => (props.isDesktop ? 'initial' : 'none')};
  @media only screen and (max-width: 960px) {
    display: ${props => (props.isDesktop ? 'none' : 'initial')};
  }
`

const HeaderMobile: React.FC<{ onMenuClick: () => void; menuIsOpen: boolean }> =
  props => (
    <VisibilityController isDesktop={false}>
      <HeaderWrapper menuIsOpen={props.menuIsOpen}>
        <MobileHeader>
          <LogoWrapper>
            <HomeLink to='/'>
              <BigLemonLogo />
            </HomeLink>
          </LogoWrapper>
          <Button
            aria-controls='main-nav-drawer'
            aria-expanded='false'
            aria-label='open navigation'
            onClick={props.onMenuClick}
          >
            <BurgerMenu />
          </Button>
        </MobileHeader>
      </HeaderWrapper>
    </VisibilityController>
  )

const MobileModal: React.FC<{
  onBackClick: () => void
  showMobileMenu: boolean
}> = props => {
  const headRef = React.useRef<HTMLButtonElement>(null)
  const tailRef = React.useRef<HTMLElement>(null)
  useTrapFocus<HTMLButtonElement, HTMLElement>(headRef, tailRef)

  return (
    <AnimatePresence>
      {props.showMobileMenu && (
        <MobileNav
          aria-modal='true'
          role='dialog'
          aria-expanded={props.showMobileMenu}
          show={props.showMobileMenu}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          id='main-nav-drawer'
        >
          <CloseModalButton onClick={props.onBackClick} ref={headRef} />
          <NavItems
            showMobileMenu={props.showMobileMenu}
            onItemClick={props.onBackClick}
            ref={tailRef}
          />
          <div />
        </MobileNav>
      )}
    </AnimatePresence>
  )
}

const NavItems = React.forwardRef<
  HTMLElement,
  {
    onItemClick: () => void
    showMobileMenu?: boolean
  }
>((props, focusRef) => {
  const isTablet = useIsTablet()
  const ref = React.useRef<HTMLElement>(null)
  useConditionalFocus(ref, [props.showMobileMenu])

  return (
    <NavList>
      <NavItem ref={ref} onClick={props.onItemClick} label='Home' href='/' />
      <NavItem onClick={props.onItemClick} label='Our work' href='/our-work' />
      <NavItem onClick={props.onItemClick} label='About us' href='/about-us' />
      <NavItem onClick={props.onItemClick} label='Jobs' href='/jobs' />
      <NavItem onClick={props.onItemClick} label='Blog' href='/blog' />
      {isTablet && (
        <NavItem
          ref={focusRef}
          onClick={props.onItemClick}
          label={quoteLinkLabel}
          href='/quote'
        />
      )}
    </NavList>
  )
})

const NavItem = React.forwardRef<any, NavItemProps>((props, ref) => {
  const isTablet = useIsTablet()

  return (
    <li onClick={props.onClick}>
      <Link to={props.href} ref={ref}>
        <NavItemText isTablet={isTablet} hasHover={true}>
          {props.label}
        </NavItemText>
      </Link>
    </li>
  )
})

const NavItemText = styled(Caption)<{ isTablet?: boolean }>`
  color: ${props => (props.isTablet ? '#fff' : 'var(--color-primary)')};
  font-family: ${({ theme }) => theme.fonts.display};
  text-align: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 1.75rem;
    font-variant: initial;
    text-transform: initial;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 1.5rem;
  }
`

const HeaderWrapper: React.FC<{ menuIsOpen?: boolean }> = props => (
  <header>
    <Nav aria-label='main' menuIsOpen={props.menuIsOpen}>
      {props.children}
    </Nav>
  </header>
)

const Nav = styled.nav<{ menuIsOpen?: boolean }>`
  display: ${props => (props.menuIsOpen ? 'none' : 'flex')};
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  min-height: ${({ theme }) => theme.heights.header};
  padding: ${({ theme }) => `0 ${theme.spacing.xl}`};
  gap: ${({ theme }) => theme.spacing.lg};
  background-color: var(--color-nav-background);
  transition-property: background-color;
  transition-duration: 1.5s;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  align-items: center;
  justify-content: center;
  & .quote-button {
    justify-self: flex-end;
    box-shadow: -3px 3px 0px 0px var(--color-nav-button-shadow);
    &:hover {
      transform: translate(-1px, 1px);
      box-shadow: -2px 2px 0px 0px var(--color-nav-button-shadow);
    }

    &:active {
      transform: translate(-3px, 3px);
      box-shadow: -1.5px 1.5px 0px 0px var(--color-nav-button-shadow);
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: ${({ theme }) => `0 ${theme.spacing.lg}`};
    gap: ${({ theme }) => theme.spacing.base};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: column;
    padding: ${({ theme }) => `0 9vw`};
  }
`

const LogoWrapper = styled.div`
  flex: 1;
  width: 220px;
`
const CTAWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const MobileHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const BurgerMenu = styled(BurgerIcon)`
  height: 40px;
  width: auto;
  cursor: pointer;
`

const MobileNav = styled(motion.div)<{ show: boolean }>`
  opacity: 0;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing.base} 0`};
  --color-focus-ring: ${({ theme }) => theme.colors.yellow.default};
  height: 100vh;
  min-height: -webkit-fill-available;
  padding-bottom: 100px;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.mobileNavigation.background};
  transition-property: background-color;
  transition-duration: 1.5s;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
`

const CloseModalButton = React.forwardRef<
  HTMLButtonElement,
  { onClick: () => void }
>((props, ref) => {
  return (
    <Button ref={ref} aria-label='close navigation' onClick={props.onClick}>
      <CloseIcon />
    </Button>
  )
})

const Button = styled.button`
  outline: inherit;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
`

const CloseIcon = styled(Cross)`
  transition: fill 0.3s ease-in-out;
  fill: ${({ theme }) => theme.colors.mobileNavigation.text};
  height: 20px;
  transform: rotate(180deg);
  cursor: pointer;
  outline: ${({ theme }) => theme.colors.yellow.default};
  &:hover {
    fill: ${({ theme }) => theme.colors.yellow.default};
  }
`

const HomeLink = styled(Link)`
  width: ${({ theme }) => theme.spacing.xl2};
  justify-self: flex-start;
  flex: auto;
  display: block;
`

const NavList = styled.ul`
  display: flex;
  flex-grow: 1;
  justify-self: center;
  justify-content: center;
  gap: 2.8125rem;
  & a {
    font-size: 1rem;
    font-variant: small-caps;
    letter-spacing: 0.25rem;
    color: var(--color-text);
    text-decoration: none;
  }

  @media screen and (max-width: 1080px) {
    gap: 2rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: column;
    justify-self: initial;
    justify-content: initial;
    align-items: center;
    flex-grow: initial;
    & a {
      color: ${({ theme }) => theme.colors.mobileNavigation.text};
    }
  }
`

export default HeaderLayout
