import styled from 'styled-components'
import { motion } from 'framer-motion'

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const AnimationContainer = motion(Container)

export default AnimationContainer
