import React from 'react'
import { IconProps } from './types'

const Cross: React.FC<IconProps> = props => {
  return (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <title>Close Menu</title>
      <g
        id='Designs'
        stroke='none'
        stroke-width='1'
        fill='none'
        fill-rule='evenodd'
      >
        <g
          id='Mobile-Menu'
          transform='translate(-204.000000, -38.000000)'
          fill='#FFFFFF'
          fill-rule='nonzero'
        >
          <path
            d='M206.267512,57.8531545 L213.999985,50.1213004 L221.73183,57.8531545 C221.926837,58.0481004 222.243731,58.0481004 222.438677,57.8531545 L223.853102,56.4387292 C224.048048,56.2437834 224.048048,55.9268896 223.853102,55.7318827 L216.121248,48.0000372 L223.853102,40.2681745 C224.048048,40.0731676 224.048048,39.7562739 223.853102,39.561328 L222.438677,38.1469027 C222.243731,37.9519569 221.926837,37.9519569 221.73183,38.1469027 L213.999985,45.8787568 L206.268122,38.1462923 C206.073115,37.9512855 205.756221,37.9512855 205.561276,38.1462923 L204.14624,39.5606566 C203.951233,39.7556635 203.951233,40.0725573 204.14624,40.2675641 L211.878704,48.0000372 L204.14624,55.7318827 C203.951233,55.9268896 203.951233,56.2437834 204.14624,56.4387292 L205.560604,57.8531545 C205.755611,58.0481004 206.072505,58.0481004 206.267512,57.8531545 Z'
            id='times'
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default Cross
