import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import SpacerComponent from '@components/SpacerComponent'

// Hooks
import useIsMobile from '@hooks/useIsMobile'

// Components
import { Caption } from '../Typography'

const FooterBottomComponent: React.FC<{ className?: string }> = props => {
  const isMobile = useIsMobile()

  return (
    <SpacerComponent bottom='sm'>
      <FooterBottomContainer className={props.className}>
        <FooterBottomLinks />
        <MadeWithLove isMobile={isMobile}>
          MADE WITH 💛 © BIG LEMON 2022
        </MadeWithLove>
      </FooterBottomContainer>
    </SpacerComponent>
  )
}

const FooterBottomContainer = styled.article`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.base};
  min-height: 2rem;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.lg};
  }
  & a {
    text-decoration: none;
    color: inherit;
  }
`

const FooterBottomLinks = () => {
  return (
    <>
      <Link to='/contact-us'>
        <FooterLinkText hasHover={true}>CONTACT US</FooterLinkText>
      </Link>
      <Link to='/jobs'>
        <FooterLinkText hasHover={true}>JOBS</FooterLinkText>
      </Link>
      <a
        href='https://www.iubenda.com/privacy-policy/99385969/legal'
        target='_blank'
      >
        <FooterLinkText hasHover={true}>POLICIES</FooterLinkText>
      </a>
      <Link to='/sitemap/sitemap-index.xml'>
        <FooterLinkText hasHover={true}>SITEMAP</FooterLinkText>
      </Link>
      <a
        href='https://www.iubenda.com/privacy-policy/99385969/cookie-policy'
        target='_blank'
      >
        <FooterLinkText hasHover={true}>COOKIES (mmm 🍪) </FooterLinkText>
      </a>
    </>
  )
}

const MadeWithLove = styled(Caption)<{ isMobile?: boolean }>`
  justify-self: flex-end;
  color: var(--color-primary);
  flex-grow: 1;
  text-align: ${({ isMobile }) => (isMobile ? 'left' : 'right')};
`

const FooterLinkText = styled(Caption)`
  color: var(--color-primary);
`

export default FooterBottomComponent
