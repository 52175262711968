import React from 'react'
import { IconProps } from './types'

const Clock: React.FC<IconProps> = props => {
  return (
    <svg
      height='17'
      viewBox='0 0 16 17'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='m762.999981 1160.25c4.28124 0 7.749981-3.46875 7.749981-7.74999s-3.468741-7.74998-7.749981-7.74998-7.749982 3.46874-7.749982 7.74998 3.468742 7.74999 7.749982 7.74999zm0-1c-3.709372 0-6.749984-3.00311-6.749984-6.74999 0-3.70937 3.003106-6.74998 6.749984-6.74998 3.709372 0 6.749984 3.00311 6.749984 6.74998 0 3.70938-3.003106 6.74999-6.749984 6.74999zm2.621881-4.07186.256225-.35312c.121887-.16876.087524-.40314-.081238-.52502l-2.203119-1.60312v-4.57186c0-.20623-.168762-.37499-.375-.37499h-.437499c-.206237 0-.374999.16876-.374999.37499v4.98748c0 .11877.056244.23126.153137.30313l2.53747 1.84375c.168762.12188.403136.08438.525023-.08124z'
        transform='translate(-755 -1144)'
      />
    </svg>
  )
}

export default Clock
