import { DefaultTheme } from 'styled-components'

export type CreatePropertyMapReturn = {
  ['--color-background']: string
  ['--color-text']: string
  ['--color-badge-text']: string
  ['--color-heading']: string
  ['--color-highlight-text']: string
  ['--color-highlight-background']: string
  ['--color-nav-background']: string
  ['--color-nav-button-shadow']: string
  ['--color-accent']: string
  ['--color-nav-text']: string
  ['--color-nav-text-active']: string
  ['--color-nav-text-hover']: string
  ['--color-certifications-logo']: string
  ['--color-certifications-invert']: string
  ['--color-nav-lemon-border']: string
  ['--color-nav-lemon-lines']: string
  ['--color-spacer']: string
  ['--color-logo']: string
  ['--color-focus-ring']: string
}
export const createPropertyMap = (
  theme: DefaultTheme
): CreatePropertyMapReturn => {
  const colors = theme.colors
  const bgColor = colors.background.default
  const textCol = colors.text
  const headings = colors.headings.default
  const highlight = colors.highlight
  const accent = colors.accent.default

  const logo = theme.isDark
    ? colors.navigation.logo?.light
    : colors.navigation.logo?.dark

  const certification = theme.isDark
    ? colors.certificationsLogo.dark
    : colors.certificationsLogo.light

  const focusRing = theme.isDark
    ? colors.yellow.default
    : colors.darkPurple.default

  const badgeText = theme.isDark ? '#fff' : '#000'

  return {
    '--color-background': bgColor,
    '--color-text': textCol,
    '--color-badge-text': badgeText,
    '--color-heading': headings,
    '--color-highlight-text': highlight.text,
    '--color-highlight-background': highlight.background,
    '--color-accent': accent,
    '--color-nav-background': colors.navigation.background,
    '--color-nav-button-shadow': colors.navigation.button.shadow,
    '--color-nav-text': colors.navigation.text.default,
    '--color-nav-text-active': colors.navigation.text.light,
    '--color-nav-text-hover': colors.navigation.text.dark,
    '--color-certifications-logo': certification,
    '--color-certifications-invert': theme.isDark ? '1' : '0',
    '--color-nav-lemon-border': colors.mobileNavigation.lemonOutline.default,
    '--color-nav-lemon-lines': colors.mobileNavigation.lemonLines.default,
    '--color-spacer': theme.colors.spacer ?? '',
    '--color-logo': logo ?? '',
    '--color-focus-ring': focusRing,
  }
}
