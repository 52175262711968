import React from 'react'
import { IconProps } from './types'

const BurgerMenu: React.FC<IconProps> = props => {
  return (
    <svg
      viewBox='0 0 38 46'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      role='img'
      {...props}
    >
      <g
        id='Designs'
        stroke='none'
        stroke-width='2'
        fill='none'
        fill-rule='evenodd'
      >
        <g
          id='Mobile-Header-Layout'
          transform='translate(-359.000000, -34.000000)'
        >
          <g id='Group-6' transform='translate(32.000000, 35.000000)'>
            <g id='Group' transform='translate(328.000000, 0.000000)'>
              <path
                stroke='var(--color-nav-lemon-border)'
                d='M0.24995319,28.8044331 C0.758404021,31.989945 1.73206574,34.7135525 3.77299094,37.0123912 C3.35585269,38.1122119 3.74781003,40.2831112 4.18809437,42 C4.55665127,43.4470031 6.02909843,44.2988299 7.47051457,43.9030573 C9.17950963,43.4335224 11.2552811,42.6981891 12.0040952,41.7865894 C15.0110515,42.4150946 17.8608179,41.9086786 20.8771853,40.7661268 C24.8173613,39.280199 28.507,36.3994015 31.3257354,32.488476 C31.7835701,31.8503054 32.222074,31.1887343 32.6313273,30.4961322 C33.0291347,29.7997148 33.3839566,29.0915972 33.7100366,28.3738142 C35.704161,23.9857233 36.3733626,19.3531995 35.7061958,15.1940259 C35.1977449,12.0105489 34.2240832,9.28490651 32.183158,6.98606779 C32.6061464,5.88624718 32.2103738,3.71941745 31.7718699,2.00050264 C31.4015325,0.555271212 29.9290854,-0.300116554 28.489704,0.0974365465 C26.7804546,0.568751899 24.7046831,1.30205042 23.9579039,2.21390446 C20.9450975,1.58336439 18.0994007,2.09359569 15.0827789,3.23233224 C11.1426029,4.71826008 7.45092942,7.60109236 4.63422881,11.5140526 C4.17461368,12.1501885 3.73789028,12.8117596 3.32482167,13.5023268 C2.92904904,14.2007791 2.57397282,14.9088967 2.24814715,15.6246449 C0.251988011,20.0127358 -0.417213658,24.6452595 0.24995319,28.8044331 Z'
                id='Fill-1'
              ></path>
              <g
                id='Group-31'
                transform='translate(12.000000, 17.000000)'
                stroke='var(--color-nav-lemon-lines)'
                stroke-linecap='round'
                stroke-width='2'
              >
                <line x1='0' y1='10.5' x2='7' y2='10.5' id='Path-3-Copy'></line>
                <line
                  x1='0'
                  y1='0.5'
                  x2='10'
                  y2='0.5'
                  id='Path-3-Copy-2'
                ></line>
                <line
                  x1='0'
                  y1='5.5'
                  x2='13'
                  y2='5.5'
                  id='Path-3-Copy-3'
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default BurgerMenu
