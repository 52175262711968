import * as React from 'react'

// Components
import { FooterHeader } from '../Typography'
import styled, { useTheme } from 'styled-components/macro'

// Hooks
import useIsMobile from '@hooks/useIsMobile'

const FooterColumnComponent: React.FC<{
  isMobile?: boolean
  heading: string
  className?: string
}> = props => {
  const theme = useTheme()
  const isMobile = useIsMobile()

  return (
    <ArticleWrapper className={props.className}>
      <FooterHeader
        // @ts-expect-error
        css={`
          padding-bottom: ${isMobile ? theme.spacing.xs : theme.spacing.lg};
          ${isMobile && `text-align: center;`}
        `}
      >
        {props.heading}
      </FooterHeader>
      {props.children}
    </ArticleWrapper>
  )
}

const ArticleWrapper = styled.article`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  }
`

export default FooterColumnComponent
