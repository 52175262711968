import styled from 'styled-components/macro'

export const Caption = styled.p<{ hasHover?: boolean }>`
  transition: color 1.5s ease-in-out;
  -webkit-transition: color 1.5s ease;
  font-family: 'neue-haas-unica', serif;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 2.8px;
  text-size-adjust: 80%;
  color: var(--color-accent);
  &:hover {
    color: ${({ hasHover }) =>
      hasHover ? 'var(--color-accent)' : 'var(--color-primary)'};
  }
`
